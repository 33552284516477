import React, { useCallback, useState } from 'react';
import axios from 'redaxios';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Button,
  Textarea,
  useDisclosure,
  Text,
} from '@chakra-ui/react';
import CaptureDetailsForm, {
  SubmitButton as ContactFormSubmitButton,
  FormInput,
} from '../capture-details-form';

import TrackingContext from '../../contexts/tracking';

const GACategory = 'call-back';

const callBackFormFields = [
  {
    name: 'firstName',
    label: 'First Name',
    required: true,
    placeholder: 'Joe/Jane',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'First name must be provided',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    required: true,
    placeholder: 'Bloggs',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Last name must be provided',
  },
  {
    name: 'email',
    label: 'Email',
    required: true,
    placeholder: 'me@bloggs.com',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Email must be provided',
  },
  {
    name: 'phoneNumber',
    label: 'Phone Number',
    required: true,
    placeholder: '+44 (0)1234 567890',
    validate: (value) =>
      (typeof value !== 'string' || value.length < 3) &&
      'Phone number must be provided',
  },
  {
    name: 'company',
    label: 'Company',
    required: false,
    placeholder: 'Bloggs Ltd.',
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    required: false,
    placeholder: 'Manager',
  },
  {
    name: 'message',
    label: 'How can we help?',
    required: false,
    placeholder: 'Your Message',
    createFormInput: (props) => (
      <FormInput
        {...props}
        renderInputElement={({
          type,
          onChange,
          onBlur,
          name,
          required,
          placeholder,
        }) => (
          <Textarea
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            required={required}
            placeholder={placeholder}
            rows={3}
            style={{ resize: 'none' }}
          />
        )}
      />
    ),
  },
];

const handleSubmit = async (formData) => {
  try {
    await axios.post('/contact', {
      data: {
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        firstName: formData.firstName,
        lastName: formData.lastName,
        title: formData.title,
        company: formData.company,
        jobTitle: formData.jobTitle,
        message: formData.message,
        trigger: 'call back form',
      },
    });
    ReactGA.event({
      category: GACategory,
      action: 'submitted',
    });
    return 'Thank you, your message has been sent.';
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      'There was an error sending your message. Please try again later or email info@timelesstime.co.uk',
    );
  }
};

const CallMeBack = ({ callBackFormProps, callBackText, ...props }) => {
  const {
    isOpen: modalIsOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const [formSent, setFormSent] = useState(false);
  const callBackButtonClicked = useCallback(() => {
    ReactGA.event({
      category: GACategory,
      action: 'opened',
    });
    onModalOpen();
    setFormSent(false);
  });

  return (
    <>
      <Button colorScheme="orange" onClick={callBackButtonClicked} {...props}>
        {callBackText}
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onClose={onModalClose}
        contentLabel="Request a Call Back"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody px={4} pt={6} pb={4}>
            <TrackingContext.Consumer>
              {(tracking) => (
                <CaptureDetailsForm
                  fields={callBackFormFields}
                  handleSubmit={async (formData) => {
                    tracking.associate(
                      formData.email,
                      formData.firstName,
                      formData.lastName,
                    );
                    const wasSubmittedText = await handleSubmit(formData);
                    setFormSent(!!wasSubmittedText);
                    return <Text>{wasSubmittedText}</Text>;
                  }}
                  {...callBackFormProps}
                  renderSubmitButton={(submitButtonProps) => (
                    <ContactFormSubmitButton {...submitButtonProps}>
                      <Button
                        ml={2}
                        colorScheme="orange"
                        variant="outline"
                        onClick={onModalClose}
                      >
                        close
                      </Button>
                    </ContactFormSubmitButton>
                  )}
                />
              )}
            </TrackingContext.Consumer>
            {formSent && <Button onClick={onModalClose}>close</Button>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

CallMeBack.defaultProps = {
  callBackText: 'Request a call back',
};

export default CallMeBack;
